import "./App.css";
import CarouselFadeExample from "./Home.js";
import Service from "./Service.js";
import About from "./AboutUS.js";
import {
  BrowserRouter,
  Routes,
  Route,
  HashRouter,
  useLocation,
} from "react-router-dom";
import Download from "./Download.js";
import Footing from "./Footer.js";
import Terms from "./TermsAndConditions.js";
import Privacy from "./PrivacyPolicy.js";
import Refund from "./RefundPolicy.js";
import Shipping from "./Shipping.js";
import Franchisee from "./franchise.js";
import ReferAndSave from "./refer-save.js";
import { useState } from "react";
import Head from "./Header.js";
import AppNotification from "./notification.js";

function App() {
  const location = useLocation();

  const pageName = useState(location.pathname);

  return (
    <>
<AppNotification/>
      <Head />
      <Routes>
        <Route path="/" element={<CarouselFadeExample />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/sconetent" element={<Service />}></Route>
        <Route path="/download" element={<Download />}></Route>
        <Route Path="/ship" element={<Shipping />}></Route>
        <Route path="/terms" element={<Terms />}></Route>
        <Route path="/privacy" element={<Privacy />}></Route>
        <Route path="/refund" element={<Refund />}></Route>
        <Route path="/shipping" element={<Shipping />}></Route>
        <Route path="/franchise" element={<Franchisee />}></Route>
        <Route path="/referAndSave" element={<ReferAndSave />}></Route>
      </Routes>
      <Footing />
    </>
  );
}

export default App;
