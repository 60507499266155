import { initializeApp } from "firebase/app";
import { getToken, getMessaging, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyB0MMSrv19f8ifexP6unvK3M2EN5sFBu08",
  authDomain: "notifications-9f5bb.firebaseapp.com",
  projectId: "notifications-9f5bb",
  storageBucket: "notifications-9f5bb.appspot.com",
  messagingSenderId: "497947405958",
  appId: "1:497947405958:web:8641f06d6b6d12c1ce6b07",
  measurementId: "G-F9XXRVBQGF"
};

console.log("*** Firebase Config ***", firebaseConfig);

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
console.log("navigator",window.navigator.serviceWorker)
export const getOrRegisterServiceWorker = () => {
  if ("serviceWorker" in navigator) {
    return window.navigator.serviceWorker
      .getRegistration("/firebase-push-notification-scope")
      .then((serviceWorker) => {
        if (serviceWorker) return serviceWorker;
        return window.navigator.serviceWorker.register(
          "firebase-messaging-sw.js",
          {
            scope: "/firebase-push-notification-scope",
          }
        );
      });
  }
  throw new Error("The browser doesn`t support service worker.");
};

export const getFirebaseToken = () =>
  getOrRegisterServiceWorker().then((serviceWorkerRegistration) =>
    getToken(messaging, {
      vapidKey:
      "BPEZxodDrjRTrTCyW4YIXqdgTz_axyvoXcE43AjoBugyrkEZClBnkHSKXWI95QHPSh0tPEgTYJCmpWYptnYj-E0",
      serviceWorkerRegistration,
    })
  );

export const onForegroundMessage = () =>
  new Promise((resolve) => onMessage(messaging, (payload) => resolve(payload)));
