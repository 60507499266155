import { useEffect, useState } from "react";
import "./App.css";
import Carousel from "react-bootstrap/Carousel";
import { Link, useNavigate, NavLink, useLocation } from "react-router-dom";
import axios from "axios";
import ReadMoreReact from "read-more-react";
import { API_CONFIG, API_HEADERS } from "./Api/API_CONFIG";
import Slider from "./slider";
import About from "./AboutUS";
import Download from "./Download";
import ReferAndSave from "./refer-save";
import Franchisee from "./franchise";
import "./App.css";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Service from "./Service";
function CarouselFadeExample() {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    if (hash === "") window.scrollTo(0, 0);
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          if (id === "") {
            window.scrollTo(0, 0);
          } else {
            element.scrollIntoView({
              block: "start",
              inline: "start",
              behavior: "smooth",
            });
          }
        }
      }, 0);
    }
  }, [pathname, hash, key]);
  return (
    <>
      {/* <div className="container-fluid pageMinHeight header-fixed naving">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Navbar expand="lg">
                <Navbar.Brand href="#home" className="w-25">
                  <Link to="/" className="home-nav">
                    {" "}
                    <img src={"/eat logo.png"} className="logo-img" alt="" />
                  </Link>
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="basic-navbar-nav " />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="margin-nav set-width">
                    <Nav.Link href="#home" className="nav-design">
                      Home
                    </Nav.Link>
                    <Nav.Link href="#aboutUs" className="nav-design">
                      Why Us
                    </Nav.Link>
                    <Nav.Link href="#download" className="nav-design">
                      Download App
                    </Nav.Link>
                    <Nav.Link href="#referAFriend" className="nav-design">
                      Refer A Friend
                    </Nav.Link>
                    <Nav.Link href="#services" className="nav-design">
                      Franchisee
                    </Nav.Link>
                    <Nav.Link href="#footer" className="nav-design nav-design2">
                      Contact Us
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </div>
          </div>
        </div>
      </div> */}

      <div className="container-fluid container-margin">
        <div className="row">
          <div className="col-12 p-0 " id="home">
            <Slider />
          </div>
          <div className="col-12 p-0 " id="aboutUs">
            <About />
          </div>

          <div className="col-12 p-0 " id="download">
            <Download />
          </div>

          <div className="col-12 p-0 " id="referAFriend">
            <ReferAndSave />
          </div>
          <div className="col-12 p-0 " id="services">
            <Franchisee />
          </div>
        </div>
      </div>
    </>
  );
}

export default CarouselFadeExample;
